.App {
	text-align: center;
}
* {
	font-family: 'Lato', '-apple-system', 'BlinkMacSystemFont', "Segoe UI", 'Roboto', "Helvetica Neue", 'Arial',
		'sans-serif', "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
.App-logo {
	animation: App-logo-spin infinite 20s linear;
	height: 40vmin;
	pointer-events: none;
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}
input:focus,
select:focus,
textarea:focus,
button:focus {
	outline: none;
}
.searchInput {
	position: relative;
	margin-right: 2em;
	float: right;
	width: 190px;
}
.searchInput i {
	position: ABSOLUTE;
	font-size: 20px;
	right: 0;
}
input[type='search' i] {
	border: none;
	float: right;
	margin-right: 7px;
	padding: 5px;
	font-size: 1.23em;
}
@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
